<template>
    <div></div>
</template>

<script>
export default {
    name: 'Settings',
    components: {
    },
    data () {
        return {
        }
    },
    computed: {
    },
    methods: {
    },
    created: function () {
    }
}
</script>